<div class="row" [style.display]="state.value === 'inactive' ? 'none' : ''">
    <div class="col-12">
        <div *ngIf="title" [class]="options.titleClass" [attr.aria-label]="title">
            <div class=" d-flex">
                <img src="assets/img/export/excel-s.svg" alt="">
                <div class="pl-3 font-sm">{{title}}</div>
            </div>
        </div>
        <div class="mt-3">
            <mat-progress-bar mode="determinate" [value]="message"></mat-progress-bar>
        </div>
    </div>
</div>