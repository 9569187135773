import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { Toast, ToastrService } from 'ngx-toastr';
import { trigger, state, style, transition, animate, keyframes } from '@angular/animations';
export interface IToastButton {
  id: string;
  title: string;
};

@Component({
  selector: 'app-custom-notifier',
  standalone: true,
  imports: [CommonModule,MatProgressBarModule],
  templateUrl: './custom-notifier.component.html',
  styleUrls: ['./custom-notifier.component.css'],
  animations: [
    trigger('flyInOut', [
      state('inactive', style({
        opacity: 0,
      })),
      transition('inactive => active', animate('400ms ease-out', keyframes([
        style({
          transform: 'translate3d(100%, 0, 0) ',
          opacity: 0,
        }),
        style({
          transform: 'translateX(0)',
          opacity: 1,
        }),
      ]))),
      transition('active => removed', animate('400ms ease-out', keyframes([
        style({
          opacity: 1,
        }),
        style({
          transform: 'translate3d(100%, 0, 0)',
          opacity: 0,
        }),
      ]))),
    ]),
  ],
  preserveWhitespaces: false,
})
export class CustomNotifierComponent extends Toast {

  action(btn: any) {
    this.toastPackage.triggerAction(btn);
    this.toastrService.clear();
    return false;
  }


}
