import {Routes } from '@angular/router';
import { AuthGuard } from './auth/service/guard/auth/auth.guard';



export const routes: Routes = [
  { path: 'auth', loadChildren: () => import('./auth/auth-routing').then((m) => m.AuthRoutes), },
  { path: '', loadChildren: () => import('./features/features-routing').then((m) => m.featureRoutes,),canMatch:[AuthGuard]},
  {path:'**' ,redirectTo:'auth'},
];
