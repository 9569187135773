import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { Location } from '@angular/common';
import { DataService } from './shared/service/data/data.service';
import { Users } from './shared/enum/users.enum';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  standalone: true,
  imports: [RouterOutlet],
})
export class AppComponent {
  title = 'GE_MY_SGE';
  readonly USERS = Users;
  constructor(
    private translate: TranslateService,
    private _dataService: DataService,
    private router: Router
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const userType = Number(localStorage.getItem('userType'));
        const routeSegments = this.router.url.split('/');
        const targetRoute =
          userType === this.USERS.ADMIN ? 'dashboard' : 'groups';
        if (routeSegments.length > 1 && routeSegments[1] !== targetRoute) {
          this._dataService.setData(null);
          this._dataService.setStudentData(null);
        }
      }
    });
    this.translate.setDefaultLang(localStorage.getItem('lang') || 'en');
  }
}
