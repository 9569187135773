import { HttpClient, HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { importProvidersFrom, ErrorHandler, APP_INITIALIZER } from "@angular/core";
import { MatNativeDateModule, MAT_DATE_LOCALE } from "@angular/material/core";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { ApplicationConfig, BrowserModule } from "@angular/platform-browser";
import { provideAnimations } from "@angular/platform-browser/animations";
import { Router, provideRouter } from "@angular/router";
import { popperVariation, provideTippyConfig } from "@ngneat/helipopper";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { provideEnvironmentNgxMask } from "ngx-mask";
import { provideToastr } from "ngx-toastr";
import { routes } from "./app-routing.module";
import { CustomNotifierComponent } from "./shared/components/custom-notifier/custom-notifier.component";
import { MyInterceptor } from "./shared/interceptor/token-interceptor";
import { CustomMatPaginatorIntl } from "./shared/paginator-translate/translatepaginator";
import { environment } from "src/environments/environment";
import * as Sentry from "@sentry/angular-ivy";

if (environment.production || environment.staging || environment.development) {
    let envVariable = environment.production ? 'Production' : (environment.staging ? 'Staging' : 'Development');
    Sentry.init({
        dsn: "https://18aa42397b2340ab9259177f569bee65@o478834.ingest.sentry.io/4505271265984512",
        integrations: [
            new Sentry.BrowserTracing({
                // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
                tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
                routingInstrumentation: Sentry.routingInstrumentation,
            }),
            new Sentry.Replay(),
        ],
        environment: envVariable,
        // Performance Monitoring
        tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });

}


export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const popoverVariation = {
    animation: "scale",
    arrow: true,
    theme: 'light',
};

export const appConfig: ApplicationConfig = {
    providers: [
        provideEnvironmentNgxMask(),
        provideToastr({
            disableTimeOut: true,
            positionClass: 'toast-bottom-right',
            tapToDismiss: false,
            toastComponent: CustomNotifierComponent
        }),
        provideTippyConfig({
            defaultVariation: 'tooltip',
            theme: 'light',
            variations: {
                tooltip: popoverVariation,
                popper: popperVariation,
            }
        }),
        importProvidersFrom(BrowserModule, MatNativeDateModule,
            TranslateModule.forRoot({
                defaultLanguage: 'en',
                loader: {
                    provide: TranslateLoader,
                    useFactory: createTranslateLoader,
                    deps: [HttpClient]
                },
            }), MatSnackBarModule),
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MyInterceptor,
            multi: true,
        },
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: MatPaginatorIntl,
            useClass: CustomMatPaginatorIntl,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => { },
            deps: [Sentry.TraceService],
            multi: true,
        },
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } },

        provideHttpClient(withInterceptorsFromDi()),
        provideAnimations(),
        provideRouter(routes)
    ]
}