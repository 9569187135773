import { Injectable, OnDestroy } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Injectable()
export class CustomMatPaginatorIntl
    extends MatPaginatorIntl
    implements OnDestroy {
    unsubscribe: Subject<void> = new Subject<void>();
    OF_LABEL = 'of';

    constructor(private translate: TranslateService) {
        super();

        this.translate.onDefaultLangChange
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(() => {
                this.getAndInitTranslations();
            });

        this.getAndInitTranslations();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    getAndInitTranslations() {
        this.translate.get([
            'ITEMS_PER_PAGE',
            'NEXT_PAGE',
            'PREVIOUS_PAGE',
            'last page',
            'first page',
            'OF_LABEL'
        ]).pipe(takeUntil(this.unsubscribe)).subscribe(translation => {
            this.itemsPerPageLabel = translation['ITEMS_PER_PAGE'];
            this.nextPageLabel = translation['NEXT_PAGE'];
            this.previousPageLabel = translation['PREVIOUS_PAGE'];
            this.lastPageLabel = translation['last page'];
            this.firstPageLabel = translation['first page'];
            this.OF_LABEL = translation['OF_LABEL'];
            this.changes.next();
        });
    }

    override getRangeLabel = (page: number, pageSize: number, length: number) => {
        if (length === 0 || pageSize === 0) {
            return `0 ${this.OF_LABEL} ${length}`;
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        const endIndex =
            startIndex < length
                ? Math.min(startIndex + pageSize, length)
                : startIndex + pageSize;
        return `${startIndex + 1} - ${endIndex} ${this.OF_LABEL} ${length}`;
    };
}

