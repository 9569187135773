import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-snackbar',
    templateUrl: './snackbar.component.html',
    styleUrls: ['./snackbar.component.css'],
    standalone: true,
    imports: [NgIf, MatIconModule, TranslateModule]
})
export class SnackbarComponent implements OnInit {
  type = 1;
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {    
  }

  ngOnInit(): void {
  }
}
